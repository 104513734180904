/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unused-modules */

export type WhiteLabelConfig = {
    ADDRESS_SID: string;
    CONVERSATIONS_SERVICE_SID: string;
    SLUG: string;
    URL: string;
    LOGO_PERSONA_IMAGE_URL: string;
};

export const whiteLabelConfig: WhiteLabelConfig[] = [
    {
        ADDRESS_SID: "IGe16c6a0e60a5078e1fae1e279ead6e4b",
        CONVERSATIONS_SERVICE_SID: "IS714a6155ca5147158c9856a25ddbacd2",
        SLUG: "tresory-bank",
        URL: "tresorybank.com",
        LOGO_PERSONA_IMAGE_URL: "https://assets.tresorybank.com/tresorybank/tresory_bot_avatar.svg"
    },
    {
        ADDRESS_SID: "IGe16c6a0e60a5078e1fae1e279ead6e4b",
        CONVERSATIONS_SERVICE_SID: "IS714a6155ca5147158c9856a25ddbacd2",
        SLUG: "tresory-bank",
        URL: "ww3.aglsolutions.com.br/tresory/hmltresorybank",
        LOGO_PERSONA_IMAGE_URL: "https://assets.tresorybank.com/tresorybank/tresory_bot_avatar.svg"
    },
    {
        ADDRESS_SID: "IGcc2d105b50ea33503eb9767317b6dcc7",
        CONVERSATIONS_SERVICE_SID: "IS714a6155ca5147158c9856a25ddbacd2",
        SLUG: "shine-bank",
        URL: "shinebk.com",
        LOGO_PERSONA_IMAGE_URL: "https://assets.tresorybank.com/shinebank/shinebank_logo.png"
    },
    {
        ADDRESS_SID: "IGcdce27e57f0508b6c80df431276a56b3",
        CONVERSATIONS_SERVICE_SID: "IS714a6155ca5147158c9856a25ddbacd2",
        SLUG: "factum-bank",
        URL: "factumbank.com.br",
        LOGO_PERSONA_IMAGE_URL: "https://app.factumbank.com.br/Resources/web.login_image_factumBP.png"
    }
];
