import log from "loglevel";
import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Key, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";

import SofiaImage from "../assets/images/sofia-bot.png";
import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import { detachFiles } from "../store/actions/genericActions";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles,
    messageButtonStyles,
    messageButtonContainerStyles
} from "./styles/MessageBubble.styles";
import { separateMessageButtons } from "../utils/separateMessageButtons";
import { whiteLabelConfig } from "../white-labels";

const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

type ImageType = {
    src: string;
    height?: number;
    width?: number;
};

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const dispatch = useDispatch();
    const [read, setRead] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, conversation, attachedFiles } = useSelector(
        (state: AppState) => ({
            conversationsClient: state.chat.conversationsClient,
            participants: state.chat.participants,
            users: state.chat.users,
            fileAttachmentConfig: state.config.fileAttachment,
            conversation: state.chat.conversation,
            attachedFiles: state.chat.attachedFiles || []
        })
    );
    const messageRef = useRef<HTMLDivElement>(null);

    const isImageMessage = useMemo(() => Boolean(message.body.split(/\bimages\s*=\s*/g)[1]), [message]);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    const isPartner = false;

    // const bankSlug = (searchParams.get("bankSlug") || searchParams.get("bankslug")) ?? "";
    const whiteLabel = whiteLabelConfig.find((wl) => window.location.href.includes(wl.URL));

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const sendMessageWithButton = async (text: string) => {
        if (!conversation) {
            log.error("Failed sending message: no conversation found");
            return;
        }
        setIsSending(true);

        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage.setBody(text);
        attachedFiles.forEach((file: File) => {
            const formData = new FormData();
            formData.append(file.name, file);
            preparedMessage.addMedia(formData);
        });
        await preparedMessage.build().send();

        dispatch(detachFiles(attachedFiles));
        setIsSending(false);
    };

    const renderImages = () => {
        const imageModified = message.body.replace(/\\/g, "");
        let imagesStringfied = imageModified.split(/\bimages\s*=\s*/g)[1];
        imagesStringfied = imagesStringfied.slice(0, imagesStringfied.length - 1);

        const images: ImageType[] = JSON.parse(imagesStringfied);

        if (typeof images !== "object" || !images.length) return null;

        return images.map((image, i) => (
            <Box key={i} as="a" href={image.src} target="_blank">
                <img
                    src={image.src}
                    alt="Something"
                    height={image.height ?? "initial"}
                    width={image.width ?? "initial"}
                />
            </Box>
        ));
    };

    return belongsToCurrentUser && message.body.includes("Preciso da sua ajuda...") ? null : (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box {...getAvatarContainerStyles(!isLastOfUserGroup)} data-testid="avatar-container">
                        {isPartner && isLastOfUserGroup && <UserIcon decorative={true} size="sizeIcon40" />}
                        {!isPartner && isLastOfUserGroup && (
                            <img
                                src={whiteLabel?.LOGO_PERSONA_IMAGE_URL ?? SofiaImage}
                                alt="Sofia Avatar"
                                style={{ objectFit: "cover", width: "inherit" }}
                            />
                        )}
                    </Box>
                )}
                <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                    </Flex>
                    <Text as="p" {...bodyStyles}>
                        {message.body && !isImageMessage ? parseMessageBody(message.body, belongsToCurrentUser) : null}
                        {!belongsToCurrentUser && Boolean(message.body) && (
                            <Box as="div" {...messageButtonContainerStyles}>
                                {separateMessageButtons(message.body).map((text, i) => (
                                    <Box
                                        key={i}
                                        type="button"
                                        as="button"
                                        {...messageButtonStyles}
                                        style={{ borderRadius: "50px" }}
                                        onClick={
                                            isSending || !isLast ? undefined : async () => sendMessageWithButton(text)
                                        }
                                        aria-disabled={isSending || !isLast}
                                        _disabled={{
                                            opacity: ".74",
                                            cursor: "not-allowed"
                                        }}
                                    >
                                        {text}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Text>
                    {isImageMessage && renderImages()}
                    {message.type === "media" ? renderMedia() : null}
                    <Flex hAlignContent={belongsToCurrentUser ? "left" : "right"}>
                        <Text {...timeStampStyles} as="p" style={{ marginLeft: 0 }}>
                            {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                message.dateCreated.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Lido
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
